<template>
  <div>
      <p class="font-title">个人申请</p>

      <div class="content">
          <a-row justify="space-between" type="flex">
            <p class="font-title">{{userInfo.legalName}}</p>
            <!-- <a-button type="primary">申请注销</a-button> -->
          </a-row>

          <a-row class="row">
              <a-col :span="12">创建账号：{{userInfo.nickName}}</a-col>
              <a-col :span="12">身份证号：{{userInfo.idCard}}</a-col>
              <a-col :span="12">注册时间：{{userInfo.createdTime}}</a-col>
              <a-col :span="12">预留手机号：{{userInfo.bankReservedMobile}}</a-col>
              <a-col :span="12">银行账号：{{userInfo.bankAccount}}</a-col>
              <a-col :span="12">开户银行：{{userInfo.openBankName}}</a-col>
              <!-- <a-col :span="12">当年交易额（元）：{{userInfo.nickName}}</a-col> -->
          </a-row>
      </div>

      <a-table :data-source="data" :columns="applyColumns" :loading="loading" :rowKey="(record,index)=>{return index}" :pagination="false">
        <template slot-scope="text" slot="status">
            <a-badge :color="text | status" :text="text | name"/>
        </template>

        <template slot-scope="text,record" slot="supplierName">
            <!-- <span style="color: rgba(85, 85, 85, 0.65)" v-if="record.icStatus === 'APPLYING'">{{text}}</span> -->
            <router-link :to="`/shop/s${record.supplierId}`">{{text}}</router-link>
        </template>

        <template slot-scope="text,record" slot="action">
            <a-space>
                <a-popconfirm v-if="record.icStatus == 'REGISTERED' && record.supplierType !== '个人卖家'" cancelText="取消" okText="确定" title="是否下载营业执照?" @confirm="() => downloadIamge(record.busiLicenseUrl,record.supplierName)">
                    <a>下载营业执照</a>
                </a-popconfirm>
                <a v-if="record.icStatus == 'CANCELAPPLIED'">注销申请书</a>
                <template v-if="record.icStatus == 'APPLYING'">
                    <router-link :to="`/account-center/info?idCard=${userInfo.idCard}&mobile=${userInfo.bankReservedMobile}&regionId=${record.regionId}&individualId=${$route.query.id}&isedit=1&regionName=${record.regionName}`">编辑申请</router-link>
                    <a-popconfirm cancelText="取消" okText="确定" title="确定要重发?" v-if="record.individualSignStatus !== 'SIGNED'" @confirm="() => sendNote(record)">
                        <a>重发短信</a>
                    </a-popconfirm>
                    <a-popconfirm cancelText="取消" okText="确定" title="是否下载申请包?" @confirm="() => download(record)">
                        <a>下载申请包</a>
                    </a-popconfirm>
                    <!-- <a-popconfirm cancelText="取消" okText="确定" title="确定要撤销申请?" @confirm="() => delApply(record.supplierId)">
                        <a>撤销申请</a>
                    </a-popconfirm> -->
                </template>
            </a-space>
        </template>
      </a-table>
      <div class="table-foot">
        <a-select style="width: 320px" showSearch placeholder="请输入关键词检索对应区域" :value="checkVal" @change="checkVal=$event" :filter-option="filterOption">
              <a-select-option v-for="item in regionName" :key="item.id">
                {{ item.regionName }}
              </a-select-option>
            </a-select>
        <span>
            <a-button style="width: 100%; margin-top: 8px;" type="dashed" icon="plus" @click="add"></a-button>
        </span>
      </div>
  </div>
</template>

<script>
const applyColumns = [
  {
    title: '纳税区域',
    dataIndex: 'regionName',
    align: 'left',
  },
  {
    title: '小规模纳税人名称',
    scopedSlots: { customRender: 'supplierName' },
    dataIndex: 'supplierName',
    align: 'left',
  },
  {
    title: '类型',
    dataIndex: 'supplierType',
    align: 'left',
  },
  {
    title: '企业统一识别码',
    dataIndex: 'unifiedCode',
    align: 'left',
  },
  {
    title: '工商状态',
    dataIndex: 'icStatus',
    scopedSlots: { customRender: 'status' },
    align: 'left',
  },
  {
    title: '注册时间',
    dataIndex: 'licenseRegTime',
    width: 170,
    align: 'left',
  },
  {
    title: '操作',
    scopedSlots: { customRender: 'action' },
    align: 'left',
  },
]
const type = {
    'REGISTERED': 'purple',
    'CANCELAPPLIED': 'geekblue',
    'APPLYING': '#bfbfbf',
    'CANCELLED': 'red',
}

import {getindividual,send,delApply} from '@/api/user'
import {regionList} from "../../api/seller";
import {name} from '@/utils/constArr'
export default {
    data() {
        return {
            applyColumns,
            data: [],
            loading: false,
            userInfo: {},
            checkVal: undefined,
            regionName: []
        }
    },
    filters: {
        status: (val)=>type[val],
        name: (val)=>name[val],
    },
    mounted() {
        this.init()
    },
    methods: {
        add() {
            if (!this.checkVal) {
                this.$message.error('请选择对应的区域')
                return
            }
            //如果选择了个人卖家
            if(this.regionName.find(item => item.id == this.checkVal).enterpriseType == '03'){
                this.$router.push(`/account-center/accomplishnom?individualId=${this.$route.query.id}&regionId=${this.checkVal}`)
                return
            }
            //如果选择了代账
            if(this.checkVal==999){
                this.$router.replace({path: '/individual',query:{
                    individualId:this.$route.query.id,
                    name:this.userInfo.legalName
                }})
                return
            }
            const regionName = this.regionName.find(item => item.id === this.checkVal).regionName
            this.$router.push(`/account-center/info?taxRegionId=${this.checkVal}&individualId=${this.$route.query.id}&regionName=${regionName}`)
        },
        init() {
            regionList({individualId: this.$route.query.id}).then((res) => {
                this.regionName = [...res.data,...this.regionName]
            });
            
            
            getindividual(this.$route.query.id).then(res => {
                console.log(res)
                this.userInfo = res.data
                this.data = res.data.taxRegionVoList
                if(!this.data.length){
                    this.regionName = [...this.regionName,{id: 999,regionName: "已有主体仅代账"}]
                }
            })
        },
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            )
        },
        // 重发签署短信
        sendNote(record){
            const config={
                individualId:this.$route.query.id,
                regionId:record.regionId,
                individualApplyId:record.individualApplyId,
            }
             send(config).then(res=>{
                 console.log(res);
                 if (res.code===200) {
                     this.$message.success(res.message)
                 }else{
                     this.$message.error(res.message)
                 }
             }).catch(r=>{
                 console.log(r);
             })
        },
        // 撤销申请
        delApply(supplierId) {
            delApply({supplierId}).then(res => {
                if (res.code === 200) {
                    this.init()
                    this.$message.success(res.message)
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        download(row) {
            window.location.href = `${process.env.VUE_APP_API_BASE_URL}/account/download?regionId=${row.regionId}&individualApplyId=${row.individualApplyId}&supplierId=${row.supplierId}`
        },
        downloadIamge(imgsrc, name) {
            //下载图片地址和图片名
            var image = new Image();
            // 解决跨域 Canvas 污染问题
            image.setAttribute("crossOrigin", "anonymous");
            image.onload = function() {
                var canvas = document.createElement("canvas");
                canvas.width = image.width;
                canvas.height = image.height;
                var context = canvas.getContext("2d");
                context.drawImage(image, 0, 0, image.width, image.height);
                var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据

                var a = document.createElement("a"); // 生成一个a元素
                var event = new MouseEvent("click"); // 创建一个单击事件
                a.download = name || "photo"; // 设置图片名称
                a.href = url; // 将生成的URL设置为a.href属性
                a.dispatchEvent(event); // 触发a的单击事件
            };
            image.src = imgsrc;
        },
    }
}
</script>

<style lang="less" scoped>
.font-title {
    font-weight: 650;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.85);
    margin-bottom: 24px;
}
.content {
    font-size: 14px;
    padding: 10px 12px;
    .row {
        /deep/ .ant-col {
            margin-bottom: 14px;
        }
    }
}
.table-foot {
    border-bottom: 1px solid #e8e8e8;
    height: 46px;
    line-height: 46px;
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    span {
        width: 228px;
        text-align: left;
        padding: 0 16px;
        i {
            cursor: pointer;
        }
    }
}
/deep/ .ant-table-placeholder {
    display: none;
  }
</style>